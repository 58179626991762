import { NotificationsService } from 'src/app/services/_core/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { Campaign } from './../../models/campaign';
import { SchApplication } from 'src/app/models/sch-application';
import { TestingProfile } from './../../models/testing-profile';
import { AppValue } from './../../models/app-value';
import { OhmVaccineHistory } from './../../models/ohm-vaccine-history';
import { SchLocation } from 'src/app/models/sch-location';
import { SchVaccine } from 'src/app/models/sch-vaccine';
import { StorageService } from './../_core/storage/storage.service';
import { UserDeviceService } from 'src/app/services/_core/user-device/user-device.service';
import { AdPopupService } from './../ad-popup/ad-popup.service';
import { CovidResultService } from './../covid-result/covid-result.service';
import { environment } from 'src/environments/environment';
import { UsersService } from 'src/app/services/users/users.service';
import { EmployeesService } from 'src/app/services/employees/employees.service';
import { SchAppointment } from 'src/app/models/sch-appointment';
import { SchedulerService } from './../scheduler/scheduler.service';
import { AuthService } from 'src/app/services/_core/auth/auth.service';
import { QuestionsService } from 'src/app/services/questions/questions.service';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { ProfileChecklistItem, Profile } from '../../models/profile';
import { Injectable } from '@angular/core';
import { catchError, first, map } from 'rxjs/operators';
import { Preference } from 'src/app/models/preference';
import { History } from 'src/app/models/history';
import * as moment from 'moment';
import { User } from 'src/app/models/user';
import { AdPopUp } from 'src/app/models/ad-popup';
import { OhmVaccineMockData } from 'src/app/models/ohm-vaccine-mock-data';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  env = environment;
  profileSubject: BehaviorSubject<Profile> = new BehaviorSubject({ profileStatus: 'Loading' });
  empProfileSubject: BehaviorSubject<Profile> = new BehaviorSubject({ profileStatus: 'Loading' });
  profileStatus: 'Loaded' | 'Loading' | 'Not-loaded' = 'Not-loaded';
  schApplications: SchApplication[] = [];
  vaccines: SchVaccine[] = [];
  vaccineSeqs: number[] = [];
  campaigns: Campaign[] = [];
  locations: SchLocation[] = [];
  daySub = Subscription;
  allowEmpFirstSeriesScheduling = false;
  allowEmpBoosterScheduling = false;
  allowOverride = false;
  overrideMedicalHold = 0;
  overrideExempt = 0;
  overrideDeferred = 0;
  realMedicalHold = 0;
  realExempt = 0;
  realDeferred = 0;
  mockPfizer1 = 0;
  mockPfizer2 = 0;
  mockPfizer3 = 0;
  mockModerna1 = 0;
  mockModerna2 = 0;
  mockModerna3 = 0;
  mockJj1 = 0;
  mockJj2 = 0;
  mockVaccineData: OhmVaccineHistory[] = Object.assign([], OhmVaccineMockData);
  realVaccineData: OhmVaccineHistory[] = [];

  constructor(
    private questionsService: QuestionsService,
    private schedulerService: SchedulerService,
    private employeesService: EmployeesService,
    private usersService: UsersService,
    private authService: AuthService,
    private covidResultService: CovidResultService,
    private adPopupService: AdPopupService,
    private userDevice: UserDeviceService,
    private storageService: StorageService,
    private http: HttpClient,
    private notifications: NotificationsService,
  ) {

  }

  /***
   * Load profile with checklist objectives
   */
  async loadProfile(userId: string, profileType: 'self' | 'guest' | 'emp' = 'self', overrideLoadMessage = ''): Promise<boolean> {
    const profile: Profile = { checklist: [] };
    const authUser: User = this.authService.getAuthUser();

    // Check if user is logged in
    if (authUser && authUser.userId && this.profileStatus !== 'Loading') {
      this.setLoadingMessage('Loading profile', overrideLoadMessage);

      // Load data from authUser
      this.profileStatus = 'Loading';
      profile.profileStatus = 'Loaded';
      profile.profileType = profileType;
      profile.firstName = authUser.firstName;
      profile.covidVaccineAllowed = authUser.covidVaccineAllowed;
      profile.covidTestingScheduled = false;

      // Set Profile Date and time calc
      const nowMoment = moment();
      const today = nowMoment.format('MM/DD/YYYY');
      // const schApplicationId = this.env.covid19Vaccine.applicationId;
      profile.timeOfDay = this.getTimeOfDay();
      profile.profileDate = today;
      profile.profileDateLong = nowMoment.format('MMMM DD, YYYY');

      // Load all Scheduling Applications
      this.setLoadingMessage('Loading applications', overrideLoadMessage);
      await this.loadSchApplications();

      // Check for employee user and get OHM data
      if (authUser.userId.length > 2 && authUser.userId.substring(0, 2).toLowerCase() !== 'ww') {
        profile.isGuest = false;
        // Load employee's OHM Vaccine History
        // this.setLoadingMessage('Loading employee profile', overrideLoadMessage);
        // await this.loadOhmVaccineHistory(profile);

        // Load User's COVID Test Status
        // this.setLoadingMessage('Loading wellness profile', overrideLoadMessage);
        // await this.loadCovidTestingProfile(profile, authUser);

        // ****** REMOVED DUE TO DISCONTINUED VACCINATION PROGRAM
        // Load Employee COVID Scheduling Status
        // this.setLoadingMessage('Loading vaccine history', overrideLoadMessage);
        // await this.getEmployeeCovidVaccStatus();
        // profile.allowEmpFirstSeriesScheduling = this.allowEmpFirstSeriesScheduling;
        // profile.allowEmpBoosterScheduling = this.allowEmpBoosterScheduling;

        // Load wellness history
        this.setLoadingMessage('Loading wellness screening history', overrideLoadMessage);
        await this.loadWellnessHistory(profile, authUser);

        // Check for missing profile data
        this.setLoadingMessage('Loading preferences', overrideLoadMessage);
        await this.checkForEmployeeInformation(profile, authUser);

      } else {
        profile.isGuest = true;
      }

      // ****** REMOVED DUE TO DISCONTINUED VACCINATION PROGRAM
      // Load COVID Vaccine Types to determine first-series vs. booster
      // this.setLoadingMessage('Loading features', overrideLoadMessage);
      // await this.schedulerService.loadCovidVaccineTypes();

      // ****** REMOVED DUE TO DISCONTINUED VACCINATION PROGRAM
      // Load User's Consents
      // this.setLoadingMessage('Checking documentation', overrideLoadMessage);
      // await this.loadConsents(profile, authUser);

      // Load Appointments
      this.setLoadingMessage('Loading appointments', overrideLoadMessage);
      const apptRes: SchAppointment = await this.loadAllUserAppointments(authUser.userId);
      profile.appointments = Object.assign([], apptRes.visits);
      await this.parseAppointments(profile);

      // Parse User's Testing appointments
      const visits: SchAppointment[] = apptRes.visits.filter(v => v.application !== this.env.covid19Vaccine.applicationId);
      // await this.parseAppointments(profile);
      profile.covidTestingAppointments = visits.filter(
        v => v.application === this.env.covid19Testing.symptomatic.applicationId ||
          v.application === this.env.covid19Testing.asymptomatic.applicationId
      );
      // console.log('profile.covidTestingAppointment', profile.covidTestingAppointment);

      // Add COVID Testing task
      this.setLoadingMessage('Checking wellness status', overrideLoadMessage);
      await this.checkCovidTestingTask(profile);

      // Add Monkeypox Screening tasks
      await this.setMonkeypoxSurveillanceTasks(profile, authUser);

      // Load reminder preferences
      this.setLoadingMessage('Checking reminders', overrideLoadMessage);
      await this.loadReminderPreferences(profile);

      // Get updated assignments
      this.setLoadingMessage('Checking assignments', overrideLoadMessage);
      await this.loadAssignmentUpdates(profile, authUser);
      console.log('after loadAssignmentUpdates', profile);

      // Load assignment text ad
      if (profile.crShowAssignments) {
        await this.setAssignmentTextPreferences(profile, authUser);
      }

      // Set profile time
      profile.profileUpdated = moment().format('MM/DD/YYYY hh:mm a');

      // Set profile
      this.setLoadingMessage(`Welcome, ${profile.firstName}!`, overrideLoadMessage);
      this.setProfile(profile, profileType);

      // console.log('*** profile loaded', profile);

      this.profileStatus = 'Loaded';
    }
    return Promise.resolve(true);
  }

  setLoadingMessage(loadMessage: string, overrideMessage: string) {
    const msg = overrideMessage ? overrideMessage : loadMessage;
    this.notifications.setLoadingMessage(msg);
  }

  /**
   * Set or update profile variable
   *
   * @param profile Profile object to update
   */
  setProfile(profile: Profile, profileType = 'self') {
    // console.log('Setting ' + profileType + ' profile', profile);
    if (profileType === 'self' || profileType === 'guest') {
      this.profileSubject.next(profile);
    } else {
      this.empProfileSubject.next(profile);
    }
  }

  /**
   * Get profile variable
   */
  getProfile(profileType = 'self'): Profile {
    return (profileType === 'self' || profileType === 'gu') ? this.profileSubject.getValue() : this.empProfileSubject.getValue();
  }

  /**
   * Loads Care Roster Assignments for Employees
   *
   * @returns promise boolean
   */
  async loadAssignmentUpdates(profile: Profile, authUser: User): Promise<boolean> {
    const schedDate = moment().format('MM/DD/YYYY');
    try {
      await this.checkForShowAssignment(profile);
      if (profile.crShowAssignments) {
        const res = await this.usersService.getAssignments(authUser.userId, schedDate).toPromise();
        if (res) {
          profile.crAssignment = res.crAssignment;
          return Promise.resolve(true);
        }
      }
    } catch (err) {
      this.notifications.handleError(err, 'loadAssignmentUpdates()');
      throw err;
    }
  }

  /**
   * Check if Care Roster Assignments are enabled
   *
   * @returns promise boolean
   */
  async checkForShowAssignment(profile: Profile): Promise<boolean> {
    try {
      // Check for show assignments flag
      const avRes = await this.schedulerService.
        getAppValues(this.env.constants.careRosterAppId, 'SHOWASSIGNMENTS').toPromise();
      console.log('checkForShowAssignment: avRes', avRes);
      if (avRes && avRes.appvalues.length > 0) {
        const appValue = avRes.appvalues[0];
        profile.crShowAssignments = (appValue !== undefined && appValue.value === 'Y');
        console.log('profile.crShowAssignments', profile);
      }
      return Promise.resolve(true);
    } catch (err) {
      console.error('Error occurred while loading showAssignments status', err);
      throw err;
    }
  }


  /**
   * Checks for required profile information that may be missing
   *
   * @param profile Profile object to update
   * @param authUser Current user to validate
   * @returns True once operations are complete
   */
  checkForEmployeeInformation(profile: Profile, authUser: User): Promise<boolean> {
    const missingCallbackPhone: ProfileChecklistItem = {
      feature: 'provide-callback-phone',
      objective: 'Provide a contact phone number',
      status: 'Action required',
      isComplete: (authUser && authUser.callbackPhone !== undefined && authUser.callbackPhone !== null && authUser.callbackPhone !== ''),
    };
    profile.checklist.push(missingCallbackPhone);
    return Promise.resolve(true);
  }

  /**
   * Check for Monkeypox surveillance
   *
   * @param profile Profile object to update
   * @param authUser Current user to validate
   * @returns True once operations are complete
   */
  async setMonkeypoxSurveillanceTasks(profile: Profile, authUser: User): Promise<boolean> {
    profile.mpInSurveillance = this.checkMonkeypoxSurveillance(authUser);
    if (profile.mpInSurveillance) {
      // Add task for reminders
      const isMpRemindersEnabled = authUser.preferences.find(p => p.prefType === 'MPREMENABLED');
      if (isMpRemindersEnabled === undefined || isMpRemindersEnabled.prefValue !== 'Y') {
        // Add Task
        const mpReminderItem: ProfileChecklistItem = {
          feature: 'set-monkeypox-reminders',
          objective: 'Set your Monkeypox Screening Reminders',
          status: 'Reminders not set',
          isComplete: false,
        };
        profile.checklist.push(mpReminderItem);

        // Add Ad Pop-up
        const ad: AdPopUp = {
          adName: 'reminders-monkeypox',
          status: 'show',
          order: 1
        };
        this.adPopupService.addAd(ad);

      } else {
        // Turn off MP Reminders
        for (const p of authUser.preferences) {
          if (p.prefType === 'MPREMENABLED') {
            p.prefValue = 'N';
          }
        }

        // Clear MP local notifications
        // this.localNotifications.clearLocalNotifications('MP');
      }

      await this.checkMonkeypoxScreeningHistory(profile, authUser);
    }
    return Promise.resolve(true);
  }

  /**
   * Check for Assignment Text Preferences
   *
   * @param profile Profile object to update
   * @param authUser Current user to validate
   * @returns True once operations are complete
   */
  async setAssignmentTextPreferences(profile: Profile, authUser: User): Promise<boolean> {
    // step 3: Are there assignments for today?
    console.log('profile', profile);
    console.log('authUser', authUser);
    if (profile.crAssignment && profile.crAssignment.length > 0) {
      const crAllowTexts = authUser.preferences.find(p => p.prefType === 'CRALLOWTEXTS');
      console.log('crAllowTexts', crAllowTexts);
      const crTextStatus: Preference = authUser.preferences.find(p => p.prefType === 'CRTEXTSTATUS');
      console.log('crTextStatus', crTextStatus);
      profile.isCrUser = crTextStatus.prefType !== 'NA';
      // step 4: If yes, has user prompted to accept assignment texts?
      if (
        crAllowTexts !== undefined &&
        crAllowTexts.prefValue !== 'Y' &&
        crTextStatus !== undefined &&
        crTextStatus.prefValue === 'NA'
      ) {
        console.log('Add pop-up service');
        // step 5: Add pop up ad to popup service
        // DISABLED: WHILE TEXTING ON HOLD
        // const ad: AdPopUp = {
        //   adName: 'assignment-text',
        //   status: 'show',
        //   order: 1
        // };
        // this.adPopupService.addAd(ad);
        // profile.isCrUser = true;
      }
    }
    // step 6: In Pop up ad, allow them to tap button to enable text.

    return Promise.resolve(true);
  }


  /**
   * Load Scheduling Applications
   *
   * @returns True if load is successful
   */
  async loadSchApplications(): Promise<boolean> {
    try {
      const appRes = await this.schedulerService.getSchApplications().toPromise();
      if (appRes && appRes.applications) {
        this.schApplications = appRes.applications;
      }
    } catch (err) {
      console.log('Error in loadSchApplications', err);
      return Promise.reject(err);
    }
    return Promise.resolve(true);
  }

  /**
   * Load OHM Vaccine History
   */
  async loadOhmVaccineHistory(profile: Profile): Promise<boolean> {
    const ohmDataRes = await this.getOhmVaccineHistory().toPromise();
    profile.ohmVaccineHistoryEntries =
      (ohmDataRes && ohmDataRes.ohmData && ohmDataRes.ohmData.length > 0) ?
        ohmDataRes.ohmData.filter(o => this.isOhmRecordCovidVaccine(o)) :
        null;

    // Populate mock data
    if (!this.env.production && this.allowOverride) {
      this.useVaccineWithMockUpData(profile);
    }

    // Update auth
    if (
      profile.ohmVaccineHistoryEntries &&
      profile.ohmVaccineHistoryEntries.length > 0
    ) {
      const authUser = this.authService.getAuthUser();
      const firstOhmVaccineEntry = profile.ohmVaccineHistoryEntries[0];
      authUser.dob = firstOhmVaccineEntry.DOB;
      authUser.gender = firstOhmVaccineEntry.Gender;
      profile.covidVaccineLastOhmVaccineHistory = profile.ohmVaccineHistoryEntries[profile.ohmVaccineHistoryEntries.length - 1];
      const isCovidBoosterDoseReceived = this.isCovidBoosterDoseReceived(profile.covidVaccineLastOhmVaccineHistory);
      if (profile.covidVaccineLastOhmVaccineHistory && !isCovidBoosterDoseReceived) {
        profile.covidVaccineLastOhmVaccineMoment = moment(profile.covidVaccineLastOhmVaccineHistory.vaccineDate, 'MM-DD-YYYY', true);
      }
    }
    return Promise.resolve(true);
  }

  /***
  * Get user's OHM Vaccine data
  */
  getOhmVaccineHistory(userId = null): Observable<any> {
    // Prepare request
    const userIdParam = userId ? `?userId=${userId}` : '';
    const url = environment.apiUrl + `/users/current/ohm-vaccines${userIdParam}`;
    // Send request
    return this.http.get(url).pipe(
      map((data: any) => data),
      catchError(error => of(error))
    );
  }

  /**
   * Determines if OHM history record is a COVID vaccine (protects against future vaccine type inclusions, e.g. flu)
   *
   * @param ohmVaccineHistory OHM history record to validate
   * @returns boolean: Returns True if record is a known COVID vaccine
   */
  isOhmRecordCovidVaccine(ohmVaccineHistory: OhmVaccineHistory): boolean {
    return (this.schedulerService.getCovidVaccineTypeFromOhmManufacturer(ohmVaccineHistory.manufacturer) !== 'Unknown');
  }

  /**
   * Gets last record of OHM history array that qualifies as a first series COVID vaccine (PF/MD dose 1/2 or JJ dose 1)
   *
   * @param ohmVaccineHistoryEntries OHM history records to
   * @returns OhmVaccineHistory: Returns last record of OHM history array that qualifies as a first series COVID vaccine
   */
  getLastCovidVaccFirstSeriesFromOhmHistory(ohmVaccineHistoryEntries: OhmVaccineHistory[]): OhmVaccineHistory {
    const ohmFirstSeriesHistory: OhmVaccineHistory[] = [];
    ohmVaccineHistoryEntries.forEach(h => {
      const covidVaccineType = this.schedulerService.getCovidVaccineTypeFromOhmManufacturer(h.manufacturer);
      let isFirstSeries = false;
      switch (covidVaccineType) {
        case 'Pfizer':
          isFirstSeries = (h.dose.toLowerCase().trim() === 'dose 1' || h.dose.toLowerCase().trim() === 'dose 2');
          break;

        case 'Moderna':
          isFirstSeries = (h.dose.toLowerCase().trim() === 'dose 1' || h.dose.toLowerCase().trim() === 'dose 2');
          break;

        case 'Jannsen':
          isFirstSeries = (h.dose.toLowerCase().trim() === 'dose 1');
          break;
      }

      if (isFirstSeries) {
        ohmFirstSeriesHistory.push(h);
      }
    });

    const lastOhmFirstSeriesHistoryRecord =
      (ohmFirstSeriesHistory.length > 0) ? ohmFirstSeriesHistory[ohmFirstSeriesHistory.length - 1] : null;

    return lastOhmFirstSeriesHistoryRecord;
  }

  /**
   * Determines if Booster Dose was already received based on OHM History
   *
   * @param lastOhmFirstSeriesHistory Last OHM History for First Series COVID Vaccine
   * @returns boolean: True if record is last first series dose
   */
  isCovidBoosterDoseReceived(lastOhmFirstSeriesHistory: OhmVaccineHistory): boolean {
    const covidVaccineType = this.schedulerService.getCovidVaccineTypeFromOhmManufacturer(lastOhmFirstSeriesHistory.manufacturer);
    switch (covidVaccineType) {
      case 'Pfizer':
        return (lastOhmFirstSeriesHistory.dose.toLowerCase().trim() === 'dose 3');

      case 'Moderna':
        return (lastOhmFirstSeriesHistory.dose.toLowerCase().trim() === 'dose 3');

      case 'Jannsen':
        return (lastOhmFirstSeriesHistory.dose.toLowerCase().trim() === 'dose 3');
    }

  }

  /**
   * Sets Appointment Location from locations array
   *
   * @param appt Appointment (visit)
   */
  setApptLocation(appt: SchAppointment) {
    if (this.locations && this.locations.length > 0) {
      const apptLocation = this.locations.filter((l: SchLocation) => l.locSeq === appt.locSeq)[0];
      appt.location = (apptLocation && apptLocation !== undefined) ? apptLocation : null;
    }
  }

  /**
   * Sets Appointment Vaccine (Appt Type) from vaccines array
   *
   * @param appt Appointment (visit)
   */
  setApptVaccine(appt: SchAppointment) {
    if (this.vaccines && this.vaccines.length > 0) {
      const apptVaccine = this.vaccines.filter((v: SchVaccine) => v.vacSeq === appt.vacSeq)[0];
      appt.vaccine = (apptVaccine && apptVaccine !== undefined) ? apptVaccine : null;
    }
  }

  /**
   * Sets Appointment SchApplication (Event)
   *
   * @param appt Appointment (visit)
   */
  setApptSchApplication(appt: SchAppointment) {
    if (this.schApplications && this.schApplications.length > 0) {
      appt.schApplication = this.schApplications.filter(a => a.application === appt.application)[0];
      appt.schApplication.description = appt.schApplication.description.replace('WorkWell ', '').trim();
    }
  }

  /**
   * Sets Appointment Formatted Scheduled Date
   *
   * @param appt Appointment (visit)
   */
  setApptFormattedScheduledDate(appt: SchAppointment) {
    if (appt.scheduleDate) {
      const schedDateMoment = moment(appt.scheduleDate, 'MM/DD/YYYY hh:mm A', true);
      const formattedSchedDate = schedDateMoment.format('MMM D, YYYY') + ' at ' + schedDateMoment.format('h:mm a');
      appt.scheduleDateFormatted = formattedSchedDate;
    }
  }

  /**
   * Sets vacSeqs for Testing Appointment Types
   *
   * @param profile Profile for referred values
   */
  setCovidTestingVacSeqs(profile: Profile) {
    // Get COVID Testing Vaccine Seqs
    profile.covidTestingVacSeqs = [];
    this.vaccines.filter(v => v.shotType === 'TESTING').forEach(v => {
      profile.covidTestingVacSeqs.push(v.vacSeq);
    });

    // Get COVID Testing Exempt Vaccine Seqs
    profile.covidTestingExemptVacSeqs = [];
    this.vaccines.filter(v => v.shotType === 'TESTING-ASYM').forEach(v => {
      profile.covidTestingExemptVacSeqs.push(v.vacSeq);
    });
  }

  /**
   * Sets vacSeqs for Fit Testing Appointment Types
   *
   * @param profile Profile for referred values
   */
   setFitTestingVacSeqs(profile: Profile) {
    // Get Fit Testing Vaccine Seqs
    profile.fitTestingVacSeqs = [];
    this.vaccines.filter(v => v.shotType === 'TEST-FIT').forEach(v => {
      profile.fitTestingVacSeqs.push(v.vacSeq);
    });
  }

  /**
   * Load COVID Test Status
   *
   * @param profile Profile for value references
   * @param authUser Target user object
   * @returns True indicating method is complete
   */
  async loadCovidTestingProfile(profile: Profile, authUser: User): Promise<boolean> {
    if (profile.profileType === 'self') {
      const validUser: User = await this.authService.validateUser().toPromise();
      // Determine if employee has a COVID-19 result and add to task list
      const testingProfile: TestingProfile = await this.covidResultService.getTestingProfile(validUser.userId);
      if (testingProfile) {

        profile.testingProfile = testingProfile;

        // Define Deferred flag
        profile.testingProfile.deferred = (testingProfile.daysSincePositive && testingProfile.daysSincePositive < 91) ? 1 : 0;

        // Update real-time flags
        this.realDeferred = profile.testingProfile.deferred;
        this.realExempt = profile.testingProfile.exempt;
        this.realMedicalHold = profile.testingProfile.medicalHold;

        // Apply override options if in non-PROD and allowOverride is set
        if (!this.env.production && this.allowOverride) {
          profile.testingProfile.medicalHold = this.overrideMedicalHold;
          profile.testingProfile.exempt = this.overrideExempt;
          profile.testingProfile.deferred = this.overrideDeferred;
        }

        // Disable Medical Hold flag
        profile.testingProfile.medicalHold = 0;

        if (testingProfile.result === 'NOT DETECTED') {
          const viewDate = (validUser.userViewResultDate) ? moment(validUser.userViewResultDate, 'MM/DD/YYYY HH:mm', true) : null;
          const collectDate = (testingProfile.collectDate) ? moment(testingProfile.collectDate, 'MM/DD/YYYY HH:mm', true) : null;
          // const now = moment();
          profile.viewedCovidResult = (viewDate && collectDate && collectDate < viewDate);
          profile.hasCovidResult = true;
          profile.hasNewCovidResult =
            ((viewDate === null && collectDate !== null) ||
              (viewDate !== null && collectDate != null && collectDate > viewDate));

          // COVID-19 Test Result item
          const covidTestResultItem: ProfileChecklistItem = {
            feature: 'covid-result',
            objective: 'Review COVID-19 Test Result',
            status: profile.viewedCovidResult ? 'Last viewed on ' + viewDate.format('MMMM DD, YYYY') : 'Not reviewed',
            isComplete: profile.viewedCovidResult,
          };

          // Populate ad if result available and never been viewed
          if (profile.hasNewCovidResult) {
            const resultAd: AdPopUp = {
              adName: 'covid-result',
              status: 'show',
              order: 1
            };
            this.adPopupService.addAd(resultAd);
          }

          profile.checklist.push(covidTestResultItem);
        }
      }
    }

    return Promise.resolve(true);

  }

  /**
   * Load User's Consents and Responses
   */
  async loadConsents(profile: Profile, authUser: User): Promise<boolean> {
    // Get Consents for user
    const consentRes = await this.schedulerService.getConsentsByAppTypeAndUserId(
      this.env.covid19Vaccine.applicationId,
      authUser.userId
    ).toPromise();
    const consents = consentRes.consents;

    // Determine if employee completed a COVID-vaccine check-in after vaccine
    const checkinConsents = consents.filter(c => c.consentType === 'COVIDVACCHECK' && c.active === 1);
    profile.covidVaccineCheckinDone = (checkinConsents.length > 0);

    return Promise.resolve(true);
  }

  async getEmployeeCovidVaccStatus() {
    try {
      let schedEmpCovidVacc: AppValue[] = [];
      const res = await this.schedulerService.getAppValues(this.env.covid19Vaccine.applicationId, 'SCHEDEMPCOVIDVAC').toPromise();
      // Prepare Admin Status select options
      if (res && res.appvalues) {
        schedEmpCovidVacc = res.appvalues;
        this.allowEmpFirstSeriesScheduling = (schedEmpCovidVacc.filter(av => av.value === '1' && av.description === 'FIRST').length > 0);
        this.allowEmpBoosterScheduling = (schedEmpCovidVacc.filter(av => av.value === '1' && av.description === 'BOOSTER').length > 0);
      }
      return Promise.resolve(true);
    } catch (err) {
      return Promise.reject(err);
    }
  }

  async setCampaignVaccineAndLocation(profile: Profile): Promise<boolean> {
    const appts = profile.appointments;
    if (appts && Array.isArray(appts) && appts.length > 0) {

      // Loop through each appt to get Campaign
      appts.forEach(async v => {
        // Parse Campaigns
        const camSeq = (v && v.camSeq) ? v.camSeq : null;
        let campaign: Campaign = null;
        if (camSeq) {
          // Load campaign
          const filteredCampaigns = this.campaigns.filter(c => c.camSeq === v.camSeq);
          if (filteredCampaigns.length > 0) {
            // Use previously referenced campaign
            campaign = filteredCampaigns[0];
            v.campaign = campaign;
          } else {
            // Search for new campaigns
            const camRes = await this.schedulerService.getCampaignBySeq(camSeq, true, true, false).toPromise();
            campaign = Object.assign({}, camRes.campaign);
            // Update visit campaign
            v.campaign = campaign;
            // Store campaign for future use
            this.campaigns.push(campaign);
          }
        }

        // console.log('---- Set Campaign', v);

        // Set Application (Events)
        this.setApptSchApplication(v);

        // Set Vaccine/Appt Types
        if (v.campaign && v.campaign.vaccines && Array.isArray(v.campaign.vaccines) && v.campaign.vaccines.length > 0) {
          v.campaign.vaccines.forEach(i => {
            const filteredVacs = this.vaccines.filter(fv => i.vacSeq === fv.vacSeq);
            if (!filteredVacs || filteredVacs.length === 0) {
              this.vaccines.push(i);
              this.vaccineSeqs.push(i.vacSeq);
            }
          });
        }

        this.setApptVaccine(v);
        // console.log('---- Set Vaccine', v);

        // Parse Locations
        if (v.campaign && v.vaccine && v.vaccine.locations) {
          v.vaccine.locations.forEach(i => {
            const existingLocs = this.locations.filter(l => i.locSeq === l.locSeq);
            if (!existingLocs || existingLocs.length === 0) {
              this.locations.push(i);
            }
          });
        }

        this.setApptLocation(v);
        // console.log('---- Set Location', v);

      });
    } else {
      // No appointments found, load all vaccines
      try {
        const vacRes = await this.schedulerService.getVaccinesAll().toPromise();
        this.vaccines = vacRes.vaccines;
      } catch (err) {
        return Promise.reject(err);
      }
    }
    return Promise.resolve(true);
  }

  /**
   * Load user's First Series COVID Vaccine scheduled appointments
   */
  async parseFirstSeriesCovidVaccineAppointments(
    profile: Profile
  ): Promise<boolean> {
    // Declare variables for calculations
    const vaccAppts = profile.appointments.filter(a => a.application === this.env.covid19Vaccine.applicationId);
    const nowMoment = moment();
    const today = nowMoment.format('MM/DD/YYYY');
    let statusText = 'Action required';
    let result;

    // await this.parseCampaignProperties(visits, isGuest);

    // Get COVID First Series Visits
    vaccAppts.forEach(async v => await this.prepareVisit(profile, v));
    const covidVaccFirstSeriesVisits = vaccAppts.filter(
      v => this.schedulerService.checkForCovidFirstSeriesVaccine(v.vacSeq)
    );

    // Get First Series Meta Data
    const hasAnyAppt = this.hasAnyActiveVisits(covidVaccFirstSeriesVisits);
    const hasUpcomingAppt = this.hasUpcomingVisit(covidVaccFirstSeriesVisits);
    const daysToNextAppt = this.getDaysToNextVisit(covidVaccFirstSeriesVisits);
    const daysSinceLastAppt = this.getDaysSinceLastVisit(covidVaccFirstSeriesVisits);
    const lastVisit = this.getLastVisit(covidVaccFirstSeriesVisits);
    const nextVisit = this.getNextVisit(covidVaccFirstSeriesVisits);

    covidVaccFirstSeriesVisits.forEach(async visit => {
      result = await this.prepareVisit(profile, visit);
      statusText = (statusText === 'Action required') ? result.statusText : statusText;
    });

    // Update Profile Vaccine First Series variables
    profile.covidVaccineAppointments = vaccAppts;
    profile.covidVaccineScheduled = hasUpcomingAppt;
    profile.covidVaccineAnyAppointments = hasAnyAppt;
    profile.covidVaccineDaysToNextVisit = daysToNextAppt;
    profile.covidVaccineNextVisit = nextVisit;
    profile.covidVaccineDaysSinceLastVisit = daysSinceLastAppt;
    profile.covidVaccineLastVisit = lastVisit;

    // If COVID-vaccine appointment found, add to tasklist + set pop-up ad
    if (
      profile.covidVaccineAllowed &&
      !profile.covidVaccineAnyAppointments &&
      !profile.covidVaccineDeclined &&
      !profile.covidVaccineIneligible &&
      (
        // Is Guest; continue
        profile.isGuest ||
        (
          // Is employee without a last covid vaccine result
          !profile.isGuest &&
          (profile.covidVaccineLastOhmVaccineHistory === undefined || !profile.covidVaccineLastOhmVaccineHistory)
        )
      )
    ) {

      if (this.allowEmpFirstSeriesScheduling) {
        // Add Appointment Requirement to list
        profile.covidVaccineAllowed = true;
        const schedVaccineItem: ProfileChecklistItem = {
          feature: 'schedule-covid-vaccine',
          objective: 'Schedule your COVID-19 Vaccine',
          status: statusText,
          isComplete: hasAnyAppt,
        };
        profile.checklist.push(schedVaccineItem);

        // Add schedule-covid-vaccine ad
        const schedCovidVaccineAd: AdPopUp = {
          adName: 'schedule-covid-vaccine',
          status: 'show',
          order: 1
        };
        this.adPopupService.addAd(schedCovidVaccineAd);
      }
    } else {
      // Add reminder pop-up
      if (daysToNextAppt >= 0 && daysToNextAppt <= 2 && nextVisit && !nextVisit.isPassed) {
        // Check local storage for data
        const lastViewedData = await this.storageService.getData('attend-covid-vaccine-' + nextVisit.aptSeq);
        const lastViewedMoment = (lastViewedData) ? moment(lastViewedData.viewed, 'MM/DD/YYYY HH:mm', true) : null;

        // if (!lastViewedMoment || lastViewedMoment.format('MM/DD/YYYY') !== today) {
        //   // console.log('parseFirstSeriesCovidVaccineAppointments: Getting: attend-appointment-' +
        //   //   nextVisit.aptSeq, lastViewedData, nextVisit);
        //   const ad: AdPopUp = {
        //     adName: 'attend-covid-vaccine',
        //     status: 'show',
        //     order: 1,
        //     visit: nextVisit
        //   };
        //   this.adPopupService.addAd(ad);
        // }

        // // Add appt-vaccine attendance task item
        // statusText = 'Scheduled for ' + nextVisit.scheduleDateFormatted;
        // const attendVaccineItem: ProfileChecklistItem = {
        //   feature: 'attend-covid-vaccine',
        //   objective: 'Attend your appointment: ' + nextVisit.visitLabel,
        //   status: statusText,
        //   isComplete: (nextVisit.adminStatus !== 'SCHEDULED' &&
        //     nextVisit.adminStatus !== 'PENDING' &&
        //     nextVisit.adminStatus !== 'CANCELED' &&
        //     nextVisit.adminStatus !== 'NOSHOW'),
        // };
        // profile.checklist.push(attendVaccineItem);
      }

      // COVID-19 Vaccine Check-in prompt
      if (
        daysSinceLastAppt >= 3 &&
        lastVisit &&
        this.schedulerService.isVisitAttended(lastVisit.adminStatus) &&
        !profile.covidVaccineCheckinDone
      ) {
        const ad: AdPopUp = {
          adName: 'covid-vaccine-checkin',
          status: 'show',
          order: 1
        };
        this.adPopupService.addAd(ad);
      }
    }

    return Promise.resolve(true);

  }

  async parseEmployeeBoosterCovidVaccineAppointments(
    profile: Profile
  ): Promise<boolean> {

    // Declare variables for calculations
    const vaccAppts: SchAppointment[] = profile.appointments.filter(
      a => a.application === this.env.covid19Testing.asymptomatic.applicationId
    );
    const statusText = 'Action required';
    const nowMoment = moment();
    const today = nowMoment.format('MM/DD/YYYY');

    // Third dose booster flag using OHM vaccine entries
    if (
      profile.ohmVaccineHistoryEntries &&
      profile.ohmVaccineHistoryEntries.length > 0
    ) {
      const lastOhmVaccHistory: OhmVaccineHistory = profile.covidVaccineLastOhmVaccineHistory;
      const lastOhmVaccMoment = profile.covidVaccineLastOhmVaccineMoment;
      // Define booster reference variables
      if (lastOhmVaccHistory) {
        // console.log('lastOhmVaccHistory', lastOhmVaccHistory);
        const firstSeriesVaccine =
          await this.schedulerService.getCovidVaccineFromOhmManufacturer(lastOhmVaccHistory.manufacturer);

        // Check if first series dosing is completed
        const isFirstSeriesCompleted =
          (firstSeriesVaccine.doseCount === this.schedulerService.getOhmVaccineDoseNumber(lastOhmVaccHistory.dose));
        if (isFirstSeriesCompleted) {
          const covidBoosterDayInterval = firstSeriesVaccine ?
            await this.schedulerService.getCovidBoosterDayInterval(firstSeriesVaccine.vacSeq) : null;
          // console.log('covidBoosterDayInterval', covidBoosterDayInterval, firstSeriesVaccine);
          const nextBoosterMoment = moment(lastOhmVaccHistory.vaccineDate, 'MM-DD-YYYY', true).add('day', covidBoosterDayInterval);
          profile.covidVaccineBoosterDate = nextBoosterMoment.format('M/D/YYYY');
          profile.covidVaccineBoosterType = this.schedulerService.getCovidVaccineTypeFromOhmManufacturer(lastOhmVaccHistory.manufacturer);
          profile.covidVaccineBoosterMonths = nowMoment.diff(lastOhmVaccMoment, 'month');
          profile.covidVaccineBoosterSeqs =
            await this.schedulerService.getCovidBoosterSeqsFromOhmManufacturer(lastOhmVaccHistory.manufacturer);

          // Check if eligible
          if (covidBoosterDayInterval) {
            const daysUntilEligible = nowMoment.diff(lastOhmVaccMoment, 'day');
            profile.covidVaccineBoosterEligiblilityDays = (covidBoosterDayInterval - daysUntilEligible);
            if (daysUntilEligible >= covidBoosterDayInterval) {
              profile.covidVaccineBoosterStatus = 'eligible';
              const covidBoosterAppts = vaccAppts.filter(
                v => this.schedulerService.checkForCovidBoosterVaccine(v.vacSeq));
              const activeBoosterAppts = covidBoosterAppts.filter(v => this.schedulerService.isVisitActive(v.adminStatus));
              const lastCovidBoosterAppt =
                (activeBoosterAppts && activeBoosterAppts.length > 0) ?
                  activeBoosterAppts[activeBoosterAppts.length - 1] :
                  null;
              // const nextVisit = covidBoosterAppts.filter(v => this.schedulerService.isVisitUpcoming(v.adminStatus))[0];
              const nextVisit = this.getNextVisit(covidBoosterAppts);
              let daysToNextAppt = -1;
              if (nextVisit !== undefined && nextVisit) {
                daysToNextAppt = this.getDaysToNextVisit(covidBoosterAppts);
                profile.covidVaccineBoosterNextVisit = nextVisit;
                profile.covidVaccineBoosterDaysToNextVisit = daysToNextAppt;
              }

              // Add COVID Booster schedule task
              const isBoosterScheduled = (lastCovidBoosterAppt !== undefined && lastCovidBoosterAppt !== null);

              if (this.allowEmpBoosterScheduling) {
                const scheduleBoosterItem: ProfileChecklistItem = {
                  feature: 'schedule-covid-vaccine-booster',
                  objective: 'Schedule your COVID-19 Vaccine Booster',
                  status: statusText,
                  isComplete: isBoosterScheduled
                };
                profile.checklist.push(scheduleBoosterItem);
              }

              // Add COVID Booster attend task if scheduled
              if (isBoosterScheduled) {
                profile.covidVaccineBoosterStatus = 'scheduled';
                const isBoosterAttended = lastCovidBoosterAppt && this.schedulerService.isVisitAttended(lastCovidBoosterAppt.adminStatus);

                // Show pop-up add in 2 days leading up to appt
                if (
                  daysToNextAppt >= 0 &&
                  daysToNextAppt <= 2 &&
                  nextVisit &&
                  !nextVisit.isPassed &&
                  isBoosterScheduled &&
                  !isBoosterAttended
                ) {
                  const lastViewedData = await this.storageService.getData('attend-covid-vaccine-booster-' + nextVisit.aptSeq);
                  const lastViewedMoment = (lastViewedData) ? moment(lastViewedData.viewed, 'MM/DD/YYYY HH:mm', true) : null;

                  // if (!lastViewedMoment || lastViewedMoment.format('MM/DD/YYYY') !== today) {
                  //   // console.log('parseEmployeeBoosterCovidVaccineAppointments: Getting: attend-appointment-' +
                  //   // nextVisit.aptSeq, lastViewedData, nextVisit);
                  //   const ad: AdPopUp = {
                  //     adName: 'attend-covid-vaccine-booster',
                  //     status: 'show',
                  //     order: 1,
                  //     visit: nextVisit
                  //   };
                  //   this.adPopupService.addAd(ad);
                  // }

                  // // Add appt-vaccine attendance task item
                  // statusText = 'Scheduled for ' + nextVisit.scheduleDateFormatted;
                  // const attendVaccineItem: ProfileChecklistItem = {
                  //   feature: 'attend-covid-vaccine-booster',
                  //   objective: 'Attend your appointment: ' + nextVisit.visitLabel,
                  //   status: statusText,
                  //   isComplete: (nextVisit.adminStatus !== 'SCHEDULED' &&
                  //     nextVisit.adminStatus !== 'PENDING' &&
                  //     nextVisit.adminStatus !== 'CANCELED' &&
                  //     nextVisit.adminStatus !== 'NOSHOW'),
                  // };
                  // profile.checklist.push(attendVaccineItem);

                }
              } else {
                // if (this.allowEmpBoosterScheduling) {
                //   // Prompt user to schedule booster
                //   const ad: AdPopUp = {
                //     adName: 'schedule-covid-vaccine-booster',
                //     status: 'show',
                //     order: 1
                //   };
                //   this.adPopupService.addAd(ad);
                // }
              }

            } else {
              profile.covidVaccineBoosterStatus = 'waiting';
            }

          } else {
            // Vaccine day interval not defined; not eligible
            profile.covidVaccineBoosterStatus = 'not-eligible';
          }
        } else {
          console.log('firstSeries not completed',
            isFirstSeriesCompleted, firstSeriesVaccine.doseCount,
            this.schedulerService.getOhmVaccineDoseNumber(lastOhmVaccHistory.dose));
          profile.covidVaccineBoosterStatus = 'not-eligible';
        }
      } else {
        console.log('No last ohm history');
        profile.covidVaccineBoosterStatus = 'not-eligible';
      }
    } else {
      console.log('No ohm history');
      profile.covidVaccineBoosterStatus = 'not-eligible';
    }

    return Promise.resolve(true);

  }

  // async parseCovidTestingAppointments(profile: Profile): Promise<boolean> {

  // }

  async parseAppointments(
    profile: Profile
  ): Promise<boolean> {

    // Declare variables for calculations
    const nowMoment = moment();
    const today = nowMoment.format('MM/DD/YYYY');
    const nextVisit = this.getNextVisit(profile.appointments);
    let result;

    // Set Campaigns, Vaccines (Appt Types), Locations
    await this.setCampaignVaccineAndLocation(profile);

    // Parse First Series Appointments
    await this.parseFirstSeriesCovidVaccineAppointments(profile);

    // Parse Booster Appointments
    await this.parseEmployeeBoosterCovidVaccineAppointments(profile);

    // Set COVID Testing Seqs
    this.setCovidTestingVacSeqs(profile);

    // Set Fit Testing Seqs
    this.setFitTestingVacSeqs(profile);

    // profile.covidTestingScheduled = false;

    // Prepare visits
    profile.appointments.forEach(async v => {
      const isScheduled = this.schedulerService.isVisitActive(v.adminStatus);
      const isUpcoming = this.schedulerService.isVisitUpcoming(v.adminStatus);
      const isAttended = this.schedulerService.isVisitAttended(v.adminStatus);
      result = await this.prepareVisit(profile, v);
      const scheduledMoment = moment(v.scheduleDate, 'MM/DD/YYYY hh:mm a');
      const nextApptScheduledMoment = moment(v.scheduleDate, 'MM/DD/YYYY hh:mm a');
      const daysToNextAppt = nextApptScheduledMoment.diff(nowMoment, 'days');
      const isToday = scheduledMoment.format('MM/DD/YYYY') === nowMoment.format('MM/DD/YYYY');

      // console.log('Checking Date', v, scheduledMoment.format('MM/DD/YYYY'), nowMoment.format('MM/DD/YYYY'), isToday);

      if ((isScheduled && scheduledMoment >= nowMoment) || isAttended || isToday) {

        // If COVID Testing, set flags and visit triggers
        if (
          (
            // NOTE: Added missing asymptomatic testing
            v.schApplication.application === this.env.covid19Testing.symptomatic.applicationId ||
            v.schApplication.application === this.env.covid19Testing.asymptomatic.applicationId
          ) &&
          (daysToNextAppt < 3 || isToday)) {
          if (profile.covidTestingScheduled === false) {
            profile.covidTestingScheduled = (isUpcoming || isToday);
          }
          profile.covidTestingNextVisit = nextVisit;
          profile.covidTestingDaysToNextVisit = daysToNextAppt;
        }

        // Handle in-app reminders
        if (scheduledMoment > nowMoment && isUpcoming && daysToNextAppt >= 0 && daysToNextAppt <= 2 && v && !v.isPassed) {
          // Add reminder checklist item
          const attendItem: ProfileChecklistItem = {
            feature: 'attend-appointment',
            objective: 'Attend your appointment: ' + v.visitLabel,
            status: 'Scheduled for ' + scheduledMoment.format('MMM D, YYYY') + ' at ' + scheduledMoment.format('hh:mm a'),
            isComplete: isAttended,
          };
          profile.checklist.push(attendItem);

          // Check local storage for data
          const lastViewedData = await this.storageService.getData('attend-appointment-' + nextVisit.aptSeq);
          const lastViewedMoment = (lastViewedData) ? moment(lastViewedData.viewed, 'MM/DD/YYYY HH:mm', true) : null;
          if (!lastViewedMoment || lastViewedMoment.format('MM/DD/YYYY') !== today) {
            // console.log('parseAppointments: Getting: attend-appointment-' + nextVisit.aptSeq, lastViewedData, nextVisit);
            // Add reminder pop-up ad
            const ad: AdPopUp = {
              adName: 'attend-appointment',
              status: 'show',
              visit: v,
              order: 1
            };
            this.adPopupService.addAd(ad);
          }
        }
      }
    });

    // console.log('**** campaigns', this.campaigns);
    // console.log('**** vaccines', this.vaccines);
    // console.log('**** locations', this.locations);
    // console.log('**** profile.appts', profile.appointments);

    return Promise.resolve(true);

  }

  /**
   * Get Wellness history for profile
   */
  async loadWellnessHistory(profile: Profile, authUser: User): Promise<boolean> {
    // Check for Today's Completed Daily Wellness Screening
    const nowMoment = moment();
    const latestHistory = await this.loadLatestHistoryEntry(this.env.covid19Screening.formId, authUser.userId, true);
    const latestSubmDate = (latestHistory && latestHistory.submission[0]) ?
      moment(latestHistory.submission[0].subDatetime, 'MM/DD/YYYY hh:mm A', true) :
      null;
    const todayIsComplete = (latestSubmDate && nowMoment.format('MM/DD/YYYY') === latestSubmDate.format('MM/DD/YYYY')) ? true : false;
    profile.screeningCompletedToday = todayIsComplete;
    profile.lastScreening = latestHistory ? latestHistory.submission[0] : null;
    // const dailyWellnessScreening: ProfileChecklistItem = {
    //   feature: 'wellness-screening',
    //   objective: 'Take daily wellness screening',
    //   status: todayIsComplete ? 'Completed at ' + latestSubmDate.format('h:mm a') : 'Not taken',
    //   isComplete: todayIsComplete,
    // };
    // profile.checklist.push(dailyWellnessScreening);

    return Promise.resolve(true);
  }

  /**
   * Get Wellness history for profile
   */
  async checkMonkeypoxScreeningHistory(profile: Profile, authUser: User): Promise<boolean> {
    // Check for Today's Completed Monkeypox Screening
    const nowMoment = moment();
    const latestHistory = await this.loadLatestHistoryEntry(this.env.monkeypoxScreening.formId, authUser.userId, false);
    const todaysHistory = (latestHistory && latestHistory.submission.length > 0) ?
      latestHistory.submission.filter(s => {
        const submDateMoment = moment(s.subDatetime, 'MM/DD/YYYY hh:mm A', true);
        const isToday = (nowMoment.format('MM/DD/YYYY') === submDateMoment.format('MM/DD/YYYY'));
        return isToday;
      }) :
      [];
    profile.mp2ndScreeningTime = '';
    profile.mp2ndScreeningReady = false;

    if (todaysHistory.length === 0) {
      const monkeypoxScreening1: ProfileChecklistItem = {
        feature: 'monkeypox-screening-1',
        objective: 'Take today\'s first Monkeypox Screening',
        status: 'Not taken',
        isComplete: false,
      };
      profile.checklist.push(monkeypoxScreening1);

      const monkeypoxScreening2: ProfileChecklistItem = {
        feature: 'monkeypox-screening-2',
        objective: `Take today\'s second Monkeypox Screening (4 hours after first)`,
        status: 'Not taken',
        isComplete: false,
      };
      profile.checklist.push(monkeypoxScreening2);

    } else if (todaysHistory.length === 1) {
      const firstSubmMoment = moment(todaysHistory[0].subDatetime, 'MM/DD/YYYY hh:mm A', true);
      const nextSubmDueMoment = firstSubmMoment.add(4, 'hours');
      const secondScreeningTime = nextSubmDueMoment.format('h:mm a');
      const now = moment();
      profile.mp2ndScreeningTime = secondScreeningTime;
      profile.mp2ndScreeningReady = now > nextSubmDueMoment;
      const monkeypoxScreening2: ProfileChecklistItem = {
        feature: 'monkeypox-screening-2',
        objective: `Take today\'s second Monkeypox Screening after ${secondScreeningTime}`,
        status: (profile.mp2ndScreeningReady) ? 'Not taken' : 'Take after ' + secondScreeningTime,
        isComplete: false,
      };
      profile.checklist.push(monkeypoxScreening2);

    }
    // const latestSubmDate = (latestHistory && latestHistory.submission[0]) ?
    //   moment(latestHistory.submission[0].subDatetime, 'MM/DD/YYYY hh:mm A', true) :
    //   null;
    // const todayIsComplete = (latestSubmDate && nowMoment.format('MM/DD/YYYY') === latestSubmDate.format('MM/DD/YYYY')) ? true : false;
    // profile.screeningCompletedToday = todayIsComplete;
    // profile.lastScreening = latestHistory ? latestHistory.submission[0] : null;

    return Promise.resolve(true);
  }


  /**
   * Check if user must schedule a COVID Test, add task
   *
   * @param profile Profile
   * @returns True, informing profile service this check is complete
   */
  checkCovidTestingTask(profile: Profile): Promise<boolean> {
    // Check for failed screening and no scheduled COVID Testing
    console.log('profile', profile.testingProfile);

    if (
      profile.lastScreening &&
      profile.screeningCompletedToday &&
      profile.lastScreening.alertComment === 'R' &&
      !profile.covidTestingScheduled &&
      profile.testingProfile &&
      profile.testingProfile.deferred !== 1
    ) {
      const covidTestingTask: ProfileChecklistItem = {
        feature: 'schedule-covid-test',
        objective: 'Schedule a Symptomatic COVID-19 Test',
        status: 'Action required',
        isComplete: false,
      };
      profile.checklist.push(covidTestingTask);
    }

    return Promise.resolve(true);
  }

  /**
   * Load reminder preferences
   */
  async loadReminderPreferences(profile: Profile): Promise<boolean> {
    if (profile.profileType === 'self') {
      // Check for Set Reminders
      const covidRemindersSet = this.checkReminderPreferences('');

      if (!covidRemindersSet) {
        // COVID Reminder
        const setReminders: ProfileChecklistItem = {
          feature: 'set-covid-reminders',
          objective: 'Set your Daily Wellness Reminders',
          status: covidRemindersSet ? 'Reminders set and ready' : 'Reminders not set',
          isComplete: covidRemindersSet,
        };
        profile.checklist.push(setReminders);

      }

      // if (!mpRemindersSet) {
      //   // Monkeypox Reminder
      //   const setMonkeypoxReminders: ProfileChecklistItem = {
      //     feature: 'set-monkeypox-reminders',
      //     objective: 'Set your Monkeypox Screening Reminders',
      //     status: covidRemindersSet ? 'Reminders set and ready' : 'Reminders not set',
      //     isComplete: covidRemindersSet,
      //   };
      //   profile.checklist.push(setMonkeypoxReminders);
      // }

    }

    return Promise.resolve(true);
  }


  /**
   * Get history entries
   *
   * @param formId Form ID
   * @param userId User ID
   * @returns Screening History entry
   */
  async loadHistory(formId, userId: string): Promise<History> {
    return this.questionsService.getHistory(formId, userId).pipe(first()).toPromise();
  }

  /**
   * Get most recent history entry
   */
  async loadLatestHistoryEntry(formId, userId: string, lastEntryOnly: boolean): Promise<History> {
    return this.questionsService.getHistory(formId, userId, lastEntryOnly).pipe(first()).toPromise();
  }

  /**
   * Get user's COVID-19 Testing appointment
   */
  async loadUserAppointmentBySchApp(userId: string, schApplicationId: string): Promise<any> {
    return this.schedulerService.getAppointmentsByUserIdAndSchApp(userId, schApplicationId).pipe(first()).toPromise();
  }

  /**
   * Get all user's appointments
   */
  async loadAllUserAppointments(userId: string): Promise<any> {
    return this.schedulerService.getAppointmentsByUserId(userId).pipe(first()).toPromise();
  }

  /**
   * Get user's COVID-19 Testing appointment location
   */
  async loadAppointmentLocation(locSeq: number): Promise<any> {
    return this.schedulerService.getLocationById(locSeq).pipe(first()).toPromise();
  }

  /**
   * Prepare appointment info
   */
  prepareVisit(profile: Profile, visit: SchAppointment): Promise<any> {
    const result = {
      statusText: 'Action required',
      hasUpcomingAppt: false,
      profile
    };
    // console.log('Received => profile', profile, ' -- appt', appt);
    if (profile && visit) {
      // Decrement index provided by API
      visit.visitIndex -= 1;
      this.setApptLocation(visit);
      this.setApptVaccine(visit);
      this.setApptSchApplication(visit);
      this.setApptFormattedScheduledDate(visit);
      const nowMoment = moment();
      const schedDateMoment = moment(visit.scheduleDate, 'MM/DD/YYYY hh:mm A', true);
      const formattedSchedDate = schedDateMoment.format('MMM D, YYYY') + ' at ' + schedDateMoment.format('h:mm a');
      visit.scheduleDateFormatted = formattedSchedDate;
      if (this.schedulerService.isVisitActive(visit.adminStatus)) {
        visit.isPassed = (schedDateMoment <= nowMoment);
        result.statusText = 'Scheduled for ' + formattedSchedDate;
        result.hasUpcomingAppt = this.schedulerService.isVisitActive(visit.adminStatus);
      }
    }
    return Promise.resolve(result);
  }

  /**
   * Has any scheduled visits (past, present, or future)
   *
   * @param visits Series of visits
   * @returns True for any valid visit found
   */
  hasAnyActiveVisits(visits: SchAppointment[]): boolean {
    const activeVisits = visits.filter((v: SchAppointment) => this.schedulerService.isVisitActive(v.adminStatus));
    return (activeVisits && activeVisits.length > 0);
  }

  /**
   * Determines if there are upcoming visits in a series
   *
   * @param visits Series of visits
   * @returns True for existing upcoming visits
   */
  hasUpcomingVisit(visits: SchAppointment[]): boolean {
    const nowMoment = moment();
    const futureVisits = visits.filter((v: SchAppointment) => nowMoment < moment(v.scheduleDate, 'MM/DD/YYYY hh:mm A', true) &&
      this.schedulerService.isVisitActive(v.adminStatus));
    return (futureVisits && futureVisits.length > 0);
  }

  /**
   * Determines total days until next appointment of a series
   *
   * @param visits Series of visits
   * @returns Number of days until next appointment
   */
  getDaysToNextVisit(visits: SchAppointment[]): number {
    const nowMoment = moment();
    const futureVisits = visits.filter((v: SchAppointment) => nowMoment < moment(v.scheduleDate, 'MM/DD/YYYY hh:mm A', true) &&
      this.schedulerService.isVisitActive(v.adminStatus));
    if (futureVisits && futureVisits.length > 0) {
      const nextVisit = futureVisits[0];
      const schedDateMoment = moment(nextVisit.scheduleDate, 'MM/DD/YYYY hh:mm A', true);
      return schedDateMoment.diff(nowMoment, 'days');
    } else {
      return -1;
    }
  }

  /**
   * Gets next visit of a series (based on current time)
   *
   * @param visits Series of visits
   * @returns Next visit
   */
  getNextVisit(visits: SchAppointment[]): SchAppointment {
    const nowMoment = moment();
    const futureVisits = visits.filter((v: SchAppointment) => {
      if (v.scheduleDate) {
        const isUpcoming = nowMoment < moment(v.scheduleDate, 'MM/DD/YYYY hh:mm A', true);
        const isActive = this.schedulerService.isVisitActive(v.adminStatus);
        return isUpcoming && isActive;
      } else {
        return false;
      }
    });
    // console.log('getNextVisit: futureVisits:', futureVisits);
    if (futureVisits && futureVisits.length > 0) {
      const nextVisit = futureVisits[0];
      return nextVisit;
    } else {
      return null;
    }
  }

  /**
   * Gets most recent past visit of a series (based on current time)
   *
   * @param visits Series of visits
   * @returns Most recent past (last) visit
   */
  getLastVisit(visits: SchAppointment[]): SchAppointment {
    const nowMoment = moment();
    const pastVisits = visits.filter((v: SchAppointment) => nowMoment > moment(v.scheduleDate, 'MM/DD/YYYY hh:mm A', true) &&
      this.schedulerService.isVisitAttended(v.adminStatus));
    if (pastVisits && pastVisits.length > 0) {
      const lastVisit = pastVisits[pastVisits.length - 1];
      return lastVisit;
    } else {
      return null;
    }
  }

  /**
   * Gets days since most recent past visit of a series (based on current time)
   *
   * @param visits Series of visits
   * @returns Number of days since most recent past (last) visit
   */
  getDaysSinceLastVisit(visits: SchAppointment[]): number {
    const nowMoment = moment();
    const pastVisits = visits.filter((v: SchAppointment) => nowMoment > moment(v.scheduleDate, 'MM/DD/YYYY hh:mm A', true) &&
      this.schedulerService.isVisitAttended(v.adminStatus));
    if (pastVisits && pastVisits.length > 0) {
      const lastVisit = pastVisits[pastVisits.length - 1];
      const schedDateMoment = moment(lastVisit.scheduleDate, 'MM/DD/YYYY hh:mm A', true);
      return nowMoment.diff(schedDateMoment, 'days');
    } else {
      return -1;
    }
  }

  /**
   * Check for reminders
   */
  checkMonkeypoxSurveillance(authUser: User): boolean {
    let inSurveillance = false;
    // Check for Monkeypox Surveillance
    if (authUser.mpActive === 1 && authUser.mpExposureDate) {
      const mpExposureMoment = moment(authUser.mpExposureDate, 'MM/DD/YYYY', true);
      if (mpExposureMoment.format('MM/DD/YYYY') !== 'Invalid date') {
        const mpExpirationMoment = mpExposureMoment.add(20, 'days');
        const today = moment();
        // console.log('Comparing mpExpirationMoment to today = ', mpExpirationMoment.format('MM/DD/YYYY'),
        // today.format('MM/DD/YYYY'), (mpExpirationMoment > today));
        if (mpExpirationMoment > today) {
          inSurveillance = true;
        }
      }
    }
    return inSurveillance;
  }

  /**
   * Check for reminders
   */
  checkReminderPreferences(prefix): boolean {
    let remindersSet = false;
    const authUser = this.authService.getAuthUser();
    authUser.preferences.forEach((pref: Preference) => {
      if (pref.prefType === prefix + 'REMENABLED') {
        remindersSet = (pref.prefValue === 'Y');
      }
    });
    return remindersSet;
  }

  /**
   * Check for employees
   */
  loadMyEmployees(userId: string): Promise<any> {
    return this.employeesService.getMyEmployees(userId).pipe(first()).toPromise();
  }

  /**
   * Get user's latest profile
   */
  async reloadMyProfile(userId: string): Promise<any> {
    try {
      return await this.usersService.getById(userId).pipe(first()).toPromise();
    } catch (err) {
      return Promise.resolve(null);
    }
  }

  /**
   * Listen for day changes
   */
  listenForDayChange() {
    setInterval(() => {
      const profile = this.getProfile();
      const today = moment().format('MM/DD/YYYY');
      const hour = Number(moment().format('HH'));
      const authUser = this.authService.getAuthUser();
      let profileUpdated = false;

      // Check for updated timeOfDay
      if (hour > 4 && hour < 12 && profile.timeOfDay !== 'morning') {
        profileUpdated = true;
      } else if (hour > 11 && hour < 18 && profile.timeOfDay !== 'afternoon') {
        profileUpdated = true;
      } else if (profile.timeOfDay !== 'night') {
        profileUpdated = true;
      }

      // Check for new day
      if (today !== profile.profileDate) {
        profileUpdated = true;
      }

      if (profileUpdated && authUser && authUser.userId) {
        const target = (
          authUser.userId.length > 2 &&
          authUser.userId.substr(0, 2).toLowerCase() === 'ww'
        ) ? 'guest' : 'self';
        this.loadProfile(authUser.userId, target);
      }
    }, 120000);
    // Was set at 60000
  }

  getTimeOfDay() {
    const hour = Number(moment().format('HH'));
    // Check for updated timeOfDay
    if (hour > 4 && hour < 12) {
      return 'morning';
    } else if (hour > 11 && hour < 18) {
      return 'afternoon';
    } else {
      return 'night';
    }
  }

  getSeason() {
    const month = Number(moment().format('M'));
    // Check for updated timeOfDay
    if (month >= 3 && month <= 5) {
      return 'spring';
    } else if (month >= 6 && month <= 8) {
      return 'summer';
    } else if (month >= 9 && month <= 11) {
      return 'autumn';
    } else {
      return 'winter';
    }
  }


  useVaccineWithMockUpData(profile: Profile) {
    const mockVaccineHistoryEntries = [];
    let useMock = false;

    // Pfizer
    if (this.mockPfizer1 === 1) {
      const vacc = this.mockVaccineData.filter(v => v.mockId === 'pfizer1')[0];
      mockVaccineHistoryEntries.push(vacc);
      useMock = true;
    }
    if (this.mockPfizer2 === 1) {
      const vacc = this.mockVaccineData.filter(v => v.mockId === 'pfizer2')[0];
      mockVaccineHistoryEntries.push(vacc);
      useMock = true;
    }
    if (this.mockPfizer3 === 1) {
      const vacc = this.mockVaccineData.filter(v => v.mockId === 'pfizer3')[0];
      mockVaccineHistoryEntries.push(vacc);
      useMock = true;
    }

    // Moderna
    if (this.mockModerna1 === 1) {
      const vacc = this.mockVaccineData.filter(v => v.mockId === 'moderna1')[0];
      mockVaccineHistoryEntries.push(vacc);
      useMock = true;
    }
    if (this.mockModerna2 === 1) {
      const vacc = this.mockVaccineData.filter(v => v.mockId === 'moderna2')[0];
      mockVaccineHistoryEntries.push(vacc);
      useMock = true;
    }
    if (this.mockModerna3 === 1) {
      const vacc = this.mockVaccineData.filter(v => v.mockId === 'moderna3')[0];
      mockVaccineHistoryEntries.push(vacc);
      useMock = true;
    }

    // J&J
    if (this.mockJj1 === 1) {
      const vacc = this.mockVaccineData.filter(v => v.mockId === 'jj1')[0];
      mockVaccineHistoryEntries.push(vacc);
      useMock = true;
    }
    if (this.mockJj2 === 1) {
      const vacc = this.mockVaccineData.filter(v => v.mockId === 'jj2')[0];
      mockVaccineHistoryEntries.push(vacc);
      useMock = true;
    }

    if (useMock) {
      this.realVaccineData = profile.ohmVaccineHistoryEntries;
      profile.ohmVaccineHistoryEntries = mockVaccineHistoryEntries;
      console.warn('Using mock vaccine data: ', profile.ohmVaccineHistoryEntries);
    }
  }
}
