// *** BUMP THIS VERSION ONLY WHEN UPDATING STARTER TEMPLATE ***
// BH Starter Template Version: 6.0.3

export const environment = {
  // Environment Name
  env: 'DEV',

  // Is Production Environment?
  production: false,

  // App Name/ID
  appName: 'MYHEALTHCHECK',

  // App Description (full name)
  appDescription: 'WorkWell',

  // App Version Number
  appVersion: '6.3.0',

  // App's REST Service API Root URL
  apiUrl: 'https://mobiledev.api.baystatehealth.org/workwell',

  // App's Uploaded Files Root URL
  filesUrl: 'https://dev.workwell.apps.baystatehealth.org',

  // Verlocker API URL
  verlockerUrl: 'https://mobiledev.api.baystatehealth.org/verlocker',

  // BH AppStore Deep Link URL
  appstoreUrl: 'https://mobile.baystatehealth.org/appstore/',

  // Reset password link URL
  resetPwdUrl: 'https://baystatehealth.service-now.com/nav_to.do?uri=%2F$pwd_reset.do%3Fsysparm_url%3Dpassword_reset',

  // Change password security questions link URL
  changePwdQuestionsUrl: 'https://baystatehealth.service-now.com/b_sp?id=pwd_reset_enrollment',

  // Set theme preference: user, light, dark
  // 'user' option will allow user to select theme preference (recommended).
  // 'light' and 'dark' will force their respective themes and not allow user to change.
  theme: 'user',

  // Store token in local storage to remember user?
  // Patient/Secure Apps should set this to false
  storeToken: true,

  // Allow Biometrics to login
  // This will allow users to login using biometrics (fingerprint, Touch ID or Face ID)
  allowBiometrics: false,

  // Require PIN code enabled on device
  // Patient/Secure Apps will require that the PIN code is enabled
  // If PIN is not in use, app will block user
  requirePin: true,

  // Require timeout
  // Patient/Secure Apps will require that timeout is enabled
  // On timeout, user will be bumped
  requireTimeout: false,

  // Timeout threshold
  // Determine the number of seconds when timeout will occur
  // 15 minutes = 900000
  timeoutThreshold: 900000,

  /*************************** */
  /** WORKWELL SPECIFIC VALUES */
  /*************************** */

  // COVID-19 Screening Configuration
  covid19Screening: {

    // COVID-19 Wellness Screening Form ID
    // PKA: FORMID
    formId: 'COVID19',

    // Default COVID-19 screening reminder notification time
    // PKA: DEFAULT_NOTIFICATION_TIME
    defaultReminderTime: '6 am',
  },

  // COVID-19 Vaccine Configuration
  covid19Vaccine: {

    // COVID-19 Vaccination Screening Form Id
    // PKA: FORMID_VACCHECK
    formId: 'COVID19VACCHECK',

    // COVID-19 Vaccination Application Id
    // PKA: SCHVACCINEAPP
    applicationId: 'WWCOVIDVAC',

    // Public Vaccine Campaign Seq
    // PKA: PUBLIC_CAMPAIGN_SEQ
    guestCamSeq: 6,

    // Employee Vaccine Capaign Seq
    // PKA: EMPLOYEE_VACCINE_CAMPAIGN_SEQ
    employeeCamSeq: 1,

    // Covid-19 Vaccine Support Campaign Seq
    // PKA: SUPPORT_CAMPAIGN_SEQ
    supportCamSeq: 2,

    // Priority Group ID for Special Public Vaccines
    // PKA: SPECIAL_CAM_PRIORITY_GRP
    specialGuestGroupId: 'PATSP',

    // COVID Vaccine Group ID for General Public
    // PKA: GEN_PUB_CAM_PRIORITY_GRP
    genGuestGroupId: 'PATGP',

    // Suspend Scheduler for all users - AppValue Seq
    // PKA: SUSPEND_SCHED_SEQ
    suspendSchedulerAvSeq: 147,

    // Suspend Guest Registration - AppValue Seq
    // pka: SUSPEND_REG_SEQ
    suspendGuestRegistrationAvSeq: 148,

    // Suspend Guest Appt Management - AppValue Seq
    // pka: SUSPEND_GUEST_SEQ
    suspendGuestApptMgmtAvSeq: 149,

    // Minimum Registration Age
    minRegAge: 5,

  },

  // COVID-19 Testing Configuration
  covid19Testing: {

    // Symptomatic Testing Config
    symptomatic: {

      // Symptomatic COVID-19 Testing information Form ID
      // PKA: FORMID_TESTSCREENING
      formId: 'COVID19TESTING',

      // Symptomatic COVID-19 Testing Application Id
      // PKA: SCHTESTINGAPP
      applicationId: 'WWCOVIDTEST',

      // Symptomatic COVID Testing Campaign Seq
      // PKA: EMPLOYEE_TESTING_CAMPAIGN_SEQ
      campaignSeq: 9,

    },

    // Asymptomatic Testing Config
    asymptomatic: {

      // Asymptomatic COVID-19 Testing information Form ID
      // PKA: FORMID_TEST_ASYM_SCREENING
      formId: 'COVID19TESTINGASYM',

      // Application Name for asymptomatic COVID-19 Testing
      // PKA: SCHTESTING_ASYM_APP
      applicationId: 'WWCOVIDTESTASYM',

      // Employee Asymptomatic COVID Testing Campaign Seq
      // PKA: EMPLOYEE_TESTING_ASYM_CAMPAIGN_SEQ
      campaignSeq: 11,

    }
  },

  // Monkey Pox Screening Configuration
  monkeypoxScreening: {

    // Monkeypox Wellness Screening Form ID
    // PKA: FORMID_MPSCREEN
    formId: 'MPSCREEN',

    // Monkeypox Wellness Screening App ID
    applicationId: 'MPSCREEN'

  },

  // General System Variables
  constants: {

    // EHS Phone Number
    // PKA: EHS_PHONE_LABEL
    ehsPhoneLabel: '413-794-6045 option 2',

    // PKA: EHS_PHONE_NUMBER
    ehsPhoneNumber: '413-794-6045',

    // Care Roster Settings' Application ID
    careRosterAppId: 'WWCAREROSTER'
  },

  // Fit Testing Configuration
  fitTesting: {

    // Fit Testing information Form ID
    formId: 'FITTESTSCREEN',

    // Fit Testing Application Id
    applicationId: 'WWFITTEST',

    // Fit Testing Campaign Seq
    campaignSeq: 12,

  },


  // COVID-Positive Screening Configuration
  covidPositiveScreening: {

    // COVID- Positive Application Id
    applicationId: 'WWCOVIDPOS',

    // Screening Form ID
    formId: 'COVIDPOSITIVE',

    // Question ID Phone Question
    phoneQsSeq: 55

  },

  // COVID Positive - Return to Work Screening Configuration
  covidPosRtwScreening: {

    // COVID- Positive Application Id
    applicationId: 'WWCOVIDPOSRTW',

    // Screening Form ID
    formId: 'COVIDPOSRTW',

    // Question ID Phone Question
    phoneQsSeq: 65

  },

};
