import { ThemeOption } from './../../../models/_core/theme-option';
import { NavigationService } from 'src/app/services/navigation/navigation.service';
import { UserState } from './../../../models/_core/user-state';
import { HelperUtilitiesService } from 'src/app/services/_core/helper-utilities/helper-utilities.service';
import { User } from 'src/app/models/user';
import { NavController, AlertController, ModalController } from '@ionic/angular';
import { environment } from '../../../../environments/environment';
import { Injectable, HostListener } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, observable, interval, BehaviorSubject, Subscription, throwError } from 'rxjs';
import { map, catchError, mapTo, first, tap } from 'rxjs/operators';
import { NotificationsService } from '../notifications/notifications.service';
import { StorageService } from '../storage/storage.service';
import { ConstantsService } from '../constants/constants.service';
import * as moment from 'moment';
import { AuthState } from 'src/app/models/_core/auth-state';
import { Preference } from 'src/app/models/preference';
// import { LocalNotificationsService } from '../../local-notifications/local-notifications.service';
import { UsersService } from '../../users/users.service';

/**
 * ID: bh-auth-service
 * Name: BH Auth Service
 * Description: Service used for managing authentication and user state
 * Version: X - Customized for WW
 *
 * ==============================
 * Change Log
 * ==============================
 * 2021-07-02 - MW - v1: Initial dev
 * 2021-07-13 - MW - v2: Implemented userState
 * 2021-07-27 - MW - v3: Improved open modal + alert handling; improved UX
 * 2022-05-23 - MW - v4: Updated depreciated value/error handling
 * 2022-05-27 - MW - v5: Implemented user state and theme subjects
 */
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  env = environment;
  authUser: BehaviorSubject<User> = new BehaviorSubject({});
  userState: UserState = {};
  userStateSubject: BehaviorSubject<UserState> = new BehaviorSubject({});
  themeSubject: BehaviorSubject<ThemeOption> = new BehaviorSubject('M');
  apiUrl: any;
  timeoutWarningMs = 60000;
  timeoutLogoutMs = 120000;
  inactivitySubject = new BehaviorSubject<number>(0);
  inactivityTimer = null;
  targetUrl = '';
  firstTimeSetup = false;

  constructor(
    private http: HttpClient,
    private notifications: NotificationsService,
    private storageService: StorageService,
    private alertCtrl: AlertController,
    private modalCtrl: ModalController,
    private helpers: HelperUtilitiesService,
    private navService: NavigationService,
    // private localNotifications: LocalNotificationsService,
    private usersService: UsersService
  ) {
    this.getUserStateFromStorage();
  }

  /**
   * Gets Auth User object
   * Recommend subscribing to authUser directly
   */
  getAuthUser(): User {
    return this.authUser.getValue();
  }

  /***
   * Updates Auth User object with provided object
   * @param authUser User object to replace existing value
   */
  async setAuthUser(authUser: User) {
    this.authUser.next(authUser);
    if (authUser.saveChanges) {
      try {
        await this.usersService.update(authUser).toPromise();
      } catch (err) {
        this.notifications.handleError(err, 'setAuthUser => updateUser');
      }
      authUser.saveChanges = false;
    }
  }

  /**
   * Gets User State object
   * Recommend subscribing to userStateSubject directly
   */
  getUserState(): UserState {
    return this.userStateSubject.getValue();
  }

  /***
   * Updates User State subject object
   * @param userState User State to update with
   */
  setUserState(userState: UserState) {
    this.userStateSubject.next(userState);
  }

  /**
   * Gets active theme
   * Recommend subscribing to themeSubject directly
   */
  getTheme(): ThemeOption {
    return this.themeSubject.getValue();
  }

  /***
   * Updates theme subject object
   * @param theme ThemeOption to update with
   */
  setTheme(theme: ThemeOption) {
    this.themeSubject.next(theme);
  }


  /***
   * Gets the user's state from storage
   */
  async getUserStateFromStorage() {
    this.userState = await this.storageService.getData('userState');
  }

  /***
   * Save the user's state to local storage
   */
  async saveUserStateToStorage() {
    if (!this.env.storeToken && this.userState.authUser.token) {
      this.userState.authUser.token = null;
    }
    this.userStateSubject.next(this.userState);
    this.storageService.saveData('userState', this.userState);
  }

  /**
   * Starts inactivity timer.
   * Should be called after successfully logging in
   */
  public startInactivityTimer() {
    if (this.env.requireTimeout) {
      this.timeoutLogoutMs = this.env.timeoutThreshold;
      this.timeoutWarningMs = this.timeoutLogoutMs - 30000;
      this.inactivityTimer = setInterval(() => {
        let time = this.inactivitySubject.getValue();
        time += 1000;
        // console.log('Inactivity: ', time)
        this.inactivitySubject.next(time);
        this.checkForTimeout();
      }, 1000);
    }
  }

  /**
   * Check for session timeout, display appropriate alert if timing out.
   */
  public async checkForTimeout() {
    const time = this.inactivitySubject.getValue();
    if (time === this.timeoutWarningMs) {
      const alert = await this.alertCtrl.create({
        header: 'Still there?',
        message: 'You will be signed out soon due to inactivity.',
        cssClass: 'wide-alert warning',
        backdropDismiss: false,
        buttons: [
          {
            text: 'Stay signed in',
            cssClass: 'primary',
            handler: (val) => {
              this.bumpInactivityTimer();
            }
          },
          {
            text: 'Sign out',
            handler: async (val) => {
              await this.dismissAllModalsAndAlerts();
              this.logout(false, true);
            }
          }
        ]
      });
      await alert.present();
    } else if (time === this.timeoutLogoutMs) {
      await this.dismissAllModalsAndAlerts();
      this.logout(true, true);
    }
  }

  /**
   * Dismisses all open alerts and modals
   */
  async dismissAllModalsAndAlerts(): Promise<boolean> {
    // Dismiss alerts
    for (let i = 0; i < 25; i++) {
      const alert = await this.alertCtrl.getTop();
      if (alert) {
        await alert.dismiss();
      } else {
        break;
      }
    }

    // Dismiss modals
    for (let i = 0; i < 25; i++) {
      const modal = await this.modalCtrl.getTop();
      if (modal) {
        await modal.dismiss();
      } else {
        break;
      }
    }

    return Promise.resolve(true);

  }

  /**
   * Bumps activity timer, preventing auto-timeout
   */
  public bumpInactivityTimer() {
    this.inactivitySubject.next(0);
  }

  /***
   * Validate User is still active in AD
   */
  validateUser(): Observable<any> {
    // Prepare request
    // const url = environment.apiUrl + `/validateAD`;
    const url = environment.apiUrl + `/users/current?formId=${this.env.covid19Screening.formId}`;
    // Send request
    return this.http.get(url).pipe(
      map((data: any) => {
        // this.handleLoginResponse(data);
        data.userId = data.userId.toLowerCase();
        data.fullName = data.firstName + ' ' + data.lastName;
        return data;
      }),
      catchError(err => of(err))
    );
  }

  validateUserForAnyForm(formid): Observable<any> {
    // Prepare request
    // const url = environment.apiUrl + `/validateAD`;
    const url = environment.apiUrl + `/users/current?formId=${formid}`;
    // Send request
    return this.http.get(url).pipe(
      map((data: any) => {
        // this.handleLoginResponse(data);
        data.userId = data.userId.toLowerCase();
        data.fullName = data.firstName + ' ' + data.lastName;
        return data;
      }),
      catchError(err => of(err))
    );
  }

  /***
   * Logs user into application
   * @param userId User ID
   * @param password  Password
   * @returns User Login Payload
   */
  login(userId, password, redirectOnError = true, clearUser = true): Observable<any> {
    // Clear user
    if (clearUser) {
      const authUser = { userId };
      this.setAuthUser(authUser);
    }

    const url = `${this.env.apiUrl}/login`;
    const body = {
      userId,
      password,
      formId: this.env.covid19Screening.formId
    };
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const options = (!redirectOnError) ? { headers: { 'BH-STAY-ON-PAGE-WITH-ERROR': 'true' } } : {};

    return this.http.post(url, body).pipe(
      map((data: any) => {
        this.handleLoginResponse(data);
        return data;
      }),
      catchError(error => of(error))
    );
  }

  loginGuest(email, genKey, dob, schApplicationId) {
    // Clear user
    let authUser: User = null;
    this.setAuthUser(authUser);
    const body = {
      email,
      genKey,
      dob,
      formId: this.env.covid19Screening.formId,
      schApp: schApplicationId
    };

    // Get user info
    const url = environment.apiUrl + '/guests/login';
    // const url = environment.API_URL + '/guest/login';
    return this.http.post(url, body).pipe(
      tap(async (data: any) => {
        // console.log('guest login data: ', data);
        authUser = data;
        if (authUser.x_status && authUser.x_status === 'S') {
          authUser.genKey = genKey;
          authUser.age = this.helpers.calcAge(authUser.dob);
          authUser.authenticated = true;
          authUser.sessionAppVersion = this.env.appVersion + '-' + this.env.env;
          this.setAuthUser(authUser);
          if (authUser.usrSeq === 0) {
            const userRes = await this.usersService.update(authUser).toPromise();
            authUser.usrSeq = userRes.usrSeq;
            this.setAuthUser(authUser);
          }
        }
        // return data;
      }),
      catchError(error => {
        // user notifications
        console.error('Caught error', error);
        return of(error);
      })
    );

  }


  /***
   * Process user response data, determining login status
   * @param data Login Response Data
   */
  async handleLoginResponse(data: any) {
    if (data.x_status && data.x_status === 'S') {
      this.startInactivityTimer();
      const authUser: User = data;
      authUser.userId = authUser.userId.toLowerCase();
      authUser.fullName = authUser.firstName + ' ' + authUser.lastName;

      // Flag identifies if user logged in with password for this session
      authUser.authenticated = true;

      // Check for new user
      if (authUser.preferences.length === 0) {
        this.firstTimeSetup = true;
        // console.log('Creating new user', authUser);
        this.setAuthUser(authUser);
        await this.initNewUser(authUser, true, true, true);
      } else {
        // Check for new device without scheduling enabled
        // let enableReminders = false;
        // let reminderTime = '1900-01-01T07:00:00-05:00';
        // const covidLocalNotification = this.localNotifications.covidLocalNotification;
        // const mpLocalNotification1 = this.localNotifications.mpLocalNotification1;
        // const mpLocalNotification2 = this.localNotifications.mpLocalNotification2;
        // for (const pref of authUser.preferences) {
        //   if (pref.prefType === 'REMENABLED') {
        //     enableReminders = (pref.prefValue === 'Y');
        //   }

        //   if (pref.prefType === 'REMTIME') {
        //     reminderTime = '1900-01-01T' + pref.prefValue;
        //   }
        // };

        // if (enableReminders && reminderTime !== '') {
        //   const hours = +reminderTime.split('T')[1].substr(0, 5).split(':')[0];
        //   const mins = +reminderTime.split('T')[1].substr(0, 5).split(':')[1];
        //   this.localNotifications.updateLocalNotifications(hours, mins, authUser.preferences, '', covidLocalNotification);
        // }

        // Add monkeypox preferencess if user doesn't have any (disabled by default)
        this.initMonkeypoxPreferences(authUser);

        // Check for Monkeypox Surveillance
        if (authUser.mpActive === 1 && authUser.mpExposureDate) {
          const mpExposureMoment = moment(authUser.mpExposureDate, 'MM/DD/YYYY', true);
          if (mpExposureMoment.format('MM/DD/YYYY') !== 'Invalid date') {
            const mpExpirationDate = mpExposureMoment.add(20, 'days');
            const today = moment();

            if (mpExpirationDate > today) {
              authUser.mpInSurveillance = true;
              // Check for new device without scheduling enabled
              let mpEnableReminders = false;
              let mpReminderTime = '1900-01-01T11:00:00-05:00';
              let mpReminderTime2 = '1900-01-01T11:00:00-05:00';
              for (const pref of authUser.preferences) {
                if (pref.prefType === 'MPREMENABLED') {
                  mpEnableReminders = (pref.prefValue === 'Y');
                }

                if (pref.prefType === 'MPREMTIME') {
                  mpReminderTime = '1900-01-01T' + pref.prefValue;
                }

                if (pref.prefType === 'MPREMTIME2') {
                  mpReminderTime2 = '1900-01-01T' + pref.prefValue;
                }
              };

              // Schedule 1st Monkeypox Screening reminder
              if (mpEnableReminders && mpReminderTime !== '') {
                const hours = +mpReminderTime.split('T')[1].substr(0, 5).split(':')[0];
                const mins = +mpReminderTime.split('T')[1].substr(0, 5).split(':')[1];
                // this.localNotifications.updateLocalNotifications(hours, mins, authUser.preferences, 'MP', mpLocalNotification1);
              }

              // Schedule 2nd Monkeypox Screening reminder
              if (mpEnableReminders && mpReminderTime2 !== '') {
                const hours = +mpReminderTime2.split('T')[1].substr(0, 5).split(':')[0];
                const mins = +mpReminderTime2.split('T')[1].substr(0, 5).split(':')[1];
                // this.localNotifications.updateLocalNotifications(hours, mins, authUser.preferences, 'MP', mpLocalNotification2);
              }

            }
          }
        }

        // Add assignment text preferences if user doesn't have any
        this.initAssignmentTextPreferences(authUser);
      }
      console.log('authUser from login: ', authUser);
      this.setAuthUser(authUser);
      this.userState.sessionAppVersion = this.env.appVersion + '-' + this.env.env;
      this.userState.userId = authUser.userId;
      this.userState.environment = this.env;
      this.userState.lastLoggedIn = moment().format('M/D/YYYY HH:mm');
      this.userState.authState = AuthState.LOGGED_IN;
      if (this.env.storeToken) {
        this.userState.authUser = authUser;
      }
      this.saveUserStateToStorage();
    }
    return;
  }

  setUserProperties(authUser: User, userData: User): User {
    // Preserve token
    let token = null;
    if (authUser.token) {
      token = authUser.token;
    }
    authUser = Object.assign({}, userData);
    this.initMonkeypoxPreferences(authUser);
    this.initAssignmentTextPreferences(authUser);
    authUser.token = token;
    return authUser;
  }

  // Init Monkeypox preferences
  initMonkeypoxPreferences(authUser: User) {
    if (authUser.preferences && authUser.preferences.find(p => p.prefType === 'MPREMENABLED') === undefined) {
      this.initReminderPreferences(authUser, 'MP');
      authUser.saveChanges = true;
    }
  }

  // Init Assignment text preferences
  initAssignmentTextPreferences(authUser: User) {
    console.log('authUser Init Assignment Text', authUser);
    if (authUser.preferences && authUser.preferences.find(p => p.prefType === 'CRALLOWTEXTS') === undefined) {
      const crAllowTexts: Preference = { upSeq: 0, prefType: 'CRALLOWTEXTS', prefValue: 'N' };
      authUser.preferences.push(crAllowTexts);
      const crTextStatus: Preference = { upSeq: 0, prefType: 'CRTEXTSTATUS', prefValue: 'NA' };
      authUser.preferences.push(crTextStatus);
      authUser.saveChanges = true;
    }
  }

  saveAssignmentTextPreferences(authUser: User) {
    if(authUser.preferences && authUser.preferences.find(p => p.prefType === 'CRALLOWTEXTS') !== undefined) {
        const crAllowTexts = authUser.preferences.find(p => p.prefType === 'CRALLOWTEXTS');
        console.log('crAllowTexts', crAllowTexts);
    }

    if(authUser.preferences && authUser.preferences.find(p => p.prefType === 'CRTEXTSTATUS') !== undefined) {
      const crTextStatus = authUser.preferences.find(p => p.prefType === 'CRTEXTSTATUS');
      console.log('crTextStatus', crTextStatus);
    }
  }

  async initNewUser(authUser: User, showFirstTimeSetup = false, storeUserData = false, setAuthUser = false): Promise<boolean> {
    // Clear any existing preferences
    authUser.preferences = [];

    this.initReminderPreferences(authUser, '');
    this.initReminderPreferences(authUser, 'MP');
    this.initAssignmentTextPreferences(authUser);
    authUser.saveChanges = false;
    // const covidLocalNotification = this.localNotifications.covidLocalNotification;

    if (showFirstTimeSetup) {
      // Default time to 7:00 AM
      // this.localNotifications.updateLocalNotifications(7, 0, authUser.preferences, '', covidLocalNotification);
    }

    // Create record
    try {
      const userRes = await this.usersService.update(authUser).toPromise();
      // Show firsttime setup
      if (showFirstTimeSetup) {
        // TODO: Replace with new ad-pop-up
        // this.localNotifications.presentFirstTimeRemindersSetup();
      }

      // Store user in local storage
      if (storeUserData) {
        this.storageService.saveData('user', this.authUser.getValue());
      }

      // Set current authUser session
      if (setAuthUser) {
        this.setAuthUser(authUser);
      }
      return Promise.resolve(true);
    } catch (err) {
      this.notifications.handleError(err, 'Initializing new user');
      return Promise.resolve(false);
    }
  }

  initReminderPreferences(authUser: User, prefix) {
    // Create default preferences
    authUser.preferences.push({
      prefType: prefix + 'REMENABLED',
      prefValue: (prefix !== 'MP') ? 'Y' : 'N'
    });

    authUser.preferences.push({
      prefType: prefix + 'REMTIME',
      prefValue: '07:00:00-05:00'
    });

    if (prefix === 'MP') {
      authUser.preferences.push({
        prefType: prefix + 'REMTIME2',
        prefValue: '07:00:00-05:00'
      });
    }

    authUser.preferences.push({
      prefType: prefix + 'REMSUN',
      prefValue: 'Y'
    });

    authUser.preferences.push({
      prefType: prefix + 'REMMON',
      prefValue: 'Y'
    });

    authUser.preferences.push({
      prefType: prefix + 'REMTUE',
      prefValue: 'Y'
    });

    authUser.preferences.push({
      prefType: prefix + 'REMWED',
      prefValue: 'Y'
    });

    authUser.preferences.push({
      prefType: prefix + 'REMTHU',
      prefValue: 'Y'
    });

    authUser.preferences.push({
      prefType: prefix + 'REMFRI',
      prefValue: 'Y'
    });

    authUser.preferences.push({
      prefType: prefix + 'REMSAT',
      prefValue: 'Y'
    });

  }

  /***
   * Logs user out
   * @param isExpired Determines if session expired
   * @param redirectToLogin Designates redirection to login page
   */
  logout(isExpired = false, redirectToLogin = true) {
    this.authUser.next(null);
    this.inactivitySubject.next(0);
    clearInterval(this.inactivityTimer);
    this.inactivityTimer = null;
    this.alertCtrl.getTop().then(alert => {
      if (alert) {
        alert.dismiss();
      }
    });

    if (isExpired) {
      this.userState.authState = AuthState.EXPIRED;
      this.notifications.showAlert('Session expired', 'You were signed out due to inactivity.', 'danger');
    } else {
      this.userState.authState = AuthState.LOGGED_OUT;
    }

    this.storageService.removeData('userState');

    if (redirectToLogin) {
      this.navService.navigateBack('login');
    }
  }
}
